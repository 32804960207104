 <template>
	<div class="HSMask">
		<div class="HSDialog" style="min-height: 700px;width: 1200px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{dialogTitle}}</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<div class="HSDialogContent">
				<!-- 入库信息 -->
				<div class="stockView" v-if="stockType == 0">
					<div class="stockRow" style="border-bottom: solid 1px #D8D8D8;background: #F8F9F9;font-size: 16px;">
						<div class="stockItem">入库单号</div>
						<div class="stockItem">供应商</div>
						<div class="stockItem">入库时间</div>
						<div class="stockItem">入库人</div>
						<div class="stockItem">入库类型</div>
						<div class="stockItem">入库数量</div>
						<div class="stockItem">入库备注</div>
					</div>
					<div class="stockRow" style="font-size: 14px;">
						<div class="stockItem">{{detailData.inOrderNo}}</div>
						<div class="stockItem">{{detailData.supplierName}}</div>
						<div class="stockItem">{{detailData.inDate}}</div>
						<div class="stockItem">{{detailData.inUserName}}</div>
						<div class="stockItem" v-if="detailData.inOrderType">{{detailData.inOrderType.name}} <span @click="handleCutClick" v-if="detailData.inOrderType.id == 1 && detailData.otherOrderId != null&&detailData.otherOrderId != ''" style="color: #f77e04;font-size: 12px;font-weight: bold;">退货出库</span></div>
						<div class="stockItem">{{detailData.orderTotalCount}}</div>
						<el-tooltip class="item" effect="dark" :content="detailData.remark" placement="bottom">
							<div class="stockItem">{{detailData.remark}}</div>
						</el-tooltip>
					</div>
				</div>
				<!-- 出库信息 -->
				<div class="stockView" v-if="stockType == 1">
					<div class="stockRow" style="border-bottom: solid 1px #D8D8D8;background: #F8F9F9;font-size: 16px;">
						<div class="stockItem">出库单号</div>
						<div class="stockItem">出库时间</div>
						<div class="stockItem">出库人</div>
						<div class="stockItem">出库类型</div>
						<div class="stockItem">出库数量</div>
						<div class="stockItem" v-if="detailData.outOrderType && detailData.outOrderType.id == 2">退货总价</div>
						<div class="stockItem">出库备注</div>
					</div>
					<div class="stockRow" style="font-size: 14px;">
						<div class="stockItem">{{detailData.outOrderNo}}</div>
						<div class="stockItem">{{detailData.outDate}}</div>
						<div class="stockItem">{{detailData.outUserName}}</div>
						<div class="stockItem" v-if="detailData.outOrderType">{{detailData.outOrderType.name}}</div>
						<div class="stockItem">{{detailData.orderTotalCount}}</div>
						<div class="stockItem" v-if="detailData.outOrderType && detailData.outOrderType.id == 2">{{detailData.orderTotalAmount}}</div>
						<el-tooltip class="item" effect="dark" :content="detailData.remark" placement="bottom">
							<div class="stockItem">{{detailData.remark}}</div>
						</el-tooltip>
					</div>
				</div>
				<!-- 盘点 -->
				<div class="stockView" v-if="stockType == 2">
					<div class="stockRow" style="border-bottom: solid 1px #D8D8D8;background: #F8F9F9;font-size: 16px;">
						<div class="stockItem">盘点单号</div>
						<div class="stockItem">盘点时间</div>
						<div class="stockItem">盘点人</div>
						<div class="stockItem">盘前总数量</div>
						<div class="stockItem">盘后总数量</div>
						<div class="stockItem">亏盈总量</div>
						<div class="stockItem">盘点备注</div>
					</div>
					<div class="stockRow" style="font-size: 14px;">
						<div class="stockItem">{{detailData.inventoryOrderNo}}</div>
						<div class="stockItem">{{detailData.inventoryDate}}</div>
						<div class="stockItem">{{detailData.inventoryUserName}}</div>
						<div class="stockItem">{{detailData.inventoryBeforeCount}}</div>
						<div class="stockItem">{{detailData.inventoryAfterCount}}</div>
						<div class="stockItem">{{detailData.inventoryAfterCount - detailData.inventoryBeforeCount}}</div>
						<el-tooltip class="item" effect="dark" :content="detailData.remark" placement="bottom">
							<div class="stockItem">{{detailData.remark}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="tableView">
					<el-table :data="tableData">
						<el-table-column prop="goodsName" label="名称" key="1">
						</el-table-column>
						<el-table-column prop="barCode" label="条形码" key="2">
						</el-table-column>
						<el-table-column prop="model" label="规格" key="3">
						</el-table-column>
						<el-table-column prop="salePrice" label="零售价" key="4">
						</el-table-column>
						<el-table-column prop="count" label="入库数量" key="5" v-if="stockType == 0">
						</el-table-column>
						<el-table-column prop="totalAmount" label="总成本价" key="6" v-if="stockType == 0">
						</el-table-column>
						<el-table-column prop="count" label="出库数量" key="8" v-if="stockType == 1">
						</el-table-column>
						<el-table-column prop="totalAmount" label="退货总价" key="12" v-if="stockType == 1">
						</el-table-column>
						<el-table-column prop="systemCount" label="盘前总数量" key="9" v-if="stockType == 2">
						</el-table-column>
						<el-table-column prop="actualCount" label="盘后总数量" key="10" v-if="stockType == 2">
						</el-table-column>
						<el-table-column prop="count" label="亏盈总量" key="11" v-if="stockType == 2">
							<template slot-scope="scope">
								{{scope.row.actualCount - scope.row.systemCount}}
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" key="7">
						</el-table-column>
					</el-table>
				</div>
				
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			stockType: {
				default: 0 //0入库单 1出库单 2盘点单
			},
			stockId: {
				default:''
			}
		},
		data() {
			return {
				dialogTitle: '',
				tableData:[],
				detailData:{},  //详情数据
			}
		},
		mounted() {
			
			if (this.stockType == 0) {
				this.dialogTitle = '入库单详情'
			}else if(this.stockType == 1){
				this.dialogTitle = '出库单详情'
			}else if(this.stockType == 2){
				this.dialogTitle = '盘点单详情'
			}
			this.getData()
		},
		methods: {
			//退货出库
			handleCutClick(){
				this.$emit("submit", {
					type: "close",
					data: {
						stockType:1,
						stockId:this.detailData.otherOrderId
					}
				})
			},
			//获取数据
			getData(){
				let params = {};
				if(this.stockType == 0){
					params.inorderId = this.stockId
					this.$http.get("/store/in/info", params).then(res => {
						if (res.code === 0) {
							this.detailData = res.data;
							this.tableData = res.data.inOrderItems;
						}
					})
				}else if(this.stockType == 1){
					params.inorderId = this.stockId
					this.$http.get("/store/out/info", params).then(res => {
						if (res.code === 0) {
							this.detailData = res.data;
							this.tableData = res.data.outOrderItems;
						}
					})
				}else if(this.stockType == 2){
					params.orderId = this.stockId
					this.$http.get("/store/inventory/info", params).then(res => {
						if (res.code === 0) {
							this.detailData = res.data;
							this.tableData = res.data.inventoryOrderItem;
						}
					})
				}
				
			},
			// 关闭
			close() {
				this.$emit("submit", {
					type: "close",
					data: ""
				})
			},

		}
	};
</script>
<style lang="scss" scoped>
	.HSDialog {
		display: flex;
		flex-direction: column;

		.HSDialogContent {
			flex: 1;
			padding: 10px 32px;
			color: #333;
			display: flex;
			flex-direction: column;

			.stockView {
				border-radius: 20px;
				border: 1px solid #D8D8D8;
				box-sizing: border-box;
				overflow: hidden;
				margin-bottom: 20px;
			}
			.stockView::-webkit-scrollbar {
				display: none;
			}

			.stockRow {
				display: flex;
				align-items: center;
				height: 44px;
				box-sizing: border-box;

				padding: 0 16px;

				.stockItem {
					flex: 1;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					word-break: break-all;
				}
			}
			.tableView{
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				overflow-y: scroll;
				height: 510px;
			}
			.tableView::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
