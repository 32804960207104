<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="searchView">
			<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
				style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleSearchClick"></el-input>
			<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
			<div class="search">
				<div class="searchTitle">入库类型:</div>
				<el-select v-model="inOrderType" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
					<el-option v-for="item in inOrderTypeOptions" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="search">
				<div class="searchTitle">入库日期:</div>
				<el-date-picker v-model="inOrderDate" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" style="width: 260px;" @change="handleSearchClick">
				</el-date-picker>
			</div>
			<div class="btnView">
				<el-button @click="exportClick" v-if="$buttonAuthority('cangku-rukuList-export')">导出</el-button>
				<el-button @click="handleResetClick">重置</el-button>
				<el-button type="primary" @click="handleAddClick">新建入库</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="view">
			<div class="tableView">
				<el-table :data="tableData">
					<el-table-column prop="inOrderNo" label="入库单号">
					</el-table-column>
					<el-table-column prop="inDate" label="入库日期">
					</el-table-column>
					<el-table-column prop="inUserName" label="入库人">
					</el-table-column>
					<el-table-column prop="inOrderType.name" label="入库类型">
					</el-table-column>
					<el-table-column prop="orderTotalAmount" label="总成本价">
					</el-table-column>
					<el-table-column prop="remark" label="入库备注">
					</el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleSeeClick(scope.row)" type="text" size="small">查看</el-button>
							<el-button type="text" size="small" v-if="scope.row.inOrderType.id == 1 && scope.row.otherOrderId == null" @click="handleReturnClick(scope.row)">退货</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogStockDetail_state">
			<dialogStockDetail @submit="submit_dialogStockDetail" :stockId="stockId" :stockType="stockType" key="1"></dialogStockDetail>
		</div>
		<div v-if="dialogStockDetail_state2">
			<dialogStockDetail @submit="submit_dialogStockDetail2" :stockId="stockId" :stockType="stockType" key="2"></dialogStockDetail>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import page from "../../../components/page.vue"
	import dialogStockDetail from "../../../components/dialogStockDetail.vue"
	export default {
		components: {
			page,
			dialogStockDetail
		},
		data() {
			return {
				page: { //分页
					pageIndex: 1,
					pageSize: 10,
					total: 0,
				},
				pageSizes: [10, 15,30,60],
				tableData: [], //表格
				keyword: '', //关键字
				inOrderDate: [], //日期
				inOrderType: '', //入库类型
				inOrderTypeOptions: [], //入库类型列表
				dialogStockDetail_state:false, //弹框列表
				dialogStockDetail_state2:false,
				stockId:'',  //跳转对象
				stockType:0,  //类型
			}
		},
		mounted() {
			this.getInOrderTypeData()
			this.getTableData()
		},
		methods: {
			//导出
			exportClick(){
				axios({
						method: 'get',
						url: process.env.VUE_APP_export+`/store/in/list/export`,
						responseType: 'blob',
						params:{
							keyword: this.keyword,
							inDateStart: new Date(this.inOrderDate[0]).getTime() / 1000,
							inDateEnd: new Date(this.inOrderDate[1]).getTime() / 1000,
							inOrderType: this.inOrderType == '' ? '' : Number(this.inOrderType)
						}
					})
					.then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data], {
							type: "application/ms-excel"
						}));
						const link = document.createElement('a');
						link.style.display = 'none';
						link.href = url;
						link.setAttribute('download', `入库导出.xls`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						window.URL.revokeObjectURL(url);
					})
			},
			//采购退货
			handleReturnClick(row){
				this.$router.push({path:'rukuReturn',query:{id:row.id}})
			},
			//搜索
			handleSearchClick(){
				if(this.inOrderDate == null){
					this.inOrderDate = [];
				}
				this.page.pageIndex = 1;
				this.getTableData()
			},
			submit_dialogStockDetail2(){
				this.dialogStockDetail_state2 = false;
			},
			//关闭详情
			submit_dialogStockDetail(obj){
				this.dialogStockDetail_state = false;
				if(obj.data.stockType){
					this.stockType = obj.data.stockType;
					this.stockId = obj.data.stockId;
					this.dialogStockDetail_state2 = true
				}
				
			},
			//查看
			handleSeeClick(row){
				this.stockType = 0;
				this.stockId = row.id;
				this.dialogStockDetail_state = true;
			},
			//新建入库
			handleAddClick(){
				this.$router.push({path:'rukuAdd',query:{}})
			},
			//获取入库类型
			getInOrderTypeData() {
				this.$http.get("/store/in/type").then(res => {
					if (res.code === 0) {
						this.inOrderTypeOptions = res.data;
					}
				})
			},
			//切换分页
			changePage(num) {
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//表格
			getTableData() {
				let params = {
					pageIndex: this.page.pageIndex,
					pageSize: this.page.pageSize,
					keyword: this.keyword,
					inDateStart: new Date(this.inOrderDate[0]).getTime() / 1000,
					inDateEnd: new Date(this.inOrderDate[1]).getTime() / 1000,
					inOrderType: this.inOrderType == '' ? '' : Number(this.inOrderType)
				}
				this.$http.post("/store/in/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//重置
			handleResetClick() {
				this.keyword = '';
				this.inOrderDate = [];
				this.inOrderType = '';
				this.page.pageIndex = 1;
				this.getTableData()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;

		.searchView {
			height: 80px;
			border-radius: 20px;
			margin-bottom: 16px;
			background: #fff;
			display: flex;
			align-items: center;
			padding: 0 20px;

			.search {
				display: flex;
				align-items: center;
				margin-right: 32px;

				.searchTitle {
					font-size: 14px;
					color: $fontColor;
					margin-right: 4px;
				}
			}

			.btnView {
				flex: 1;
				text-align: right;
			}
		}

		.view {
			border-radius: 20px;
			overflow: hidden;
			flex: 1;
			background: #fff;
			display: flex;
			flex-direction: column;

			.tableView {
				flex: 1;
				overflow-y: scroll;
			}
			.tableView::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
